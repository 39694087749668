@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

body {
    font-family: 'RecifeDisplay';
}

/* Add this to your CSS file */
@media (min-width: 1445px) {
    .bg-custom {
      background-image: url('/public/Images/Navbar/Bg2.png'); /* Use a larger image for bigger screens */
    }
  }
  
